import { acceptHMRUpdate, defineStore } from 'pinia'

const initialState = () => ({
  preventDoubleReset: false,
  higher: false,
})

const state = initialState

export type MiscIntercomState = ReturnType<typeof state>

export const useMiscIntercomStore = defineStore('miscIntercom', {
  state: (): MiscIntercomState => ({ ...state() }),
  actions: {
    SET() {
      this.higher = true
    },
    RESET() {
      if (!this.preventDoubleReset) {
        this.higher = false
        this.preventDoubleReset = true
      }
      setTimeout(() => {
        this.SET_PREVENT_DOUBLE_RESET(false)
      }, 500)
    },
    SET_PREVENT_DOUBLE_RESET(payload: boolean) {
      this.preventDoubleReset = payload
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useMiscIntercomStore, import.meta.hot))
